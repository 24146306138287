'use client'

import Link from '@/components/link'
import { cls } from '@/utils'
import IconLink from '@/components/icon-link'
import { ButtonMore } from '../button-more'
import LogoSymbol from '@/public/assets/logo-symbol.svg'
import LogoFull from '@/public/assets/logo-full.svg'
import IconDots from '@haiper/icons-svg/icons/outline/dot-hor.svg'
import ThemeToggle from '@/components/theme-toggle'
import IconBeta from '@/public/assets/badge-beta.svg'
import { NO_OUTLINE_STYLE, sidebarWidthStyle } from '@/constants'
import useNavLinks from '@/hooks/useNavLinks'
import useFooterLinks from '@/hooks/useFooterLinks'

interface NavProps {
  className?: string
  activeKey?: string
}

const MoreOutline = IconDots

export function Nav({ className, activeKey }: NavProps) {
  const { data: navLinks } = useNavLinks()
  const { data: footerLinks } = useFooterLinks()
  return (
    <nav
      className={cls(
        'hidden md:flex flex-col shrink-0 overflow-y-auto no-scrollbar border-r-2 widescreen:border-0 border-solid border-border h-auto',
        sidebarWidthStyle,
        className, // external className
      )}
      data-testid='sidebar'
    >
      <div className='w-full flex justify-center widescreen:justify-start py-6 widescreen:pl-6'>
        <Link
          href='https://haiper.ai/home'
          target='_blank'
          rel='noopener noreferer'
          className={cls(
            'focus-visible:ring-0 text-icon relative',
            NO_OUTLINE_STYLE,
          )}
          aria-label='sidebar logo'
        >
          <LogoSymbol className='h-8 block widescreen:hidden mx-auto' />
          <LogoFull height={48} className='h-8 hidden widescreen:block' />
          <IconBeta
            className={cls(
              'mt-2 widescreen:mt-0 widescreen:absolute widescreen:top-0 widescreen:right-0 widescreen:transform widescreen:translate-x-[22px] widescreen:translate-y-[-7px] widescreen:z-10',
            )}
          />
        </Link>
      </div>
      <div className='h-0 widescreen:hidden border border-border border-solid w-8 mx-auto mb-3'></div>
      {/* <div className='h-0.5 mx-6 border border-solid border-border mb-4' /> */}
      <div className='flex-1 flex flex-col p-2 mb-3 gap-2'>
        <div className='min-h-0 flex-1 flex flex-col gap-1' aria-label='nav'>
          {navLinks.map(
            ({
              href,
              icon,
              activeIcon,
              label,
              key,
              onClick,
              className,
              iconClassName,
              labelClassName,
            }) => (
              <IconLink
                key={key}
                href={href}
                icon={key === activeKey ? activeIcon : icon}
                iconClassName={iconClassName}
                labelClassName={labelClassName}
                className={className}
                active={key === activeKey}
                title={label}
                onClick={onClick}
              >
                {label}
              </IconLink>
            ),
          )}
        </div>
        <div className='flex flex-col gap-1' aria-label='footer'>
          {footerLinks.map(({ href, icon, activeIcon, label, key, target }) => (
            <IconLink
              key={key}
              href={href}
              icon={key === activeKey ? activeIcon : icon}
              target={target}
              title={label}
              active={key === activeKey}
            >
              {label}
            </IconLink>
          ))}
          <ButtonMore>
            <IconLink
              icon={MoreOutline}
              className={cls('flex appearance-none')}
              href='#'
            >
              More
            </IconLink>
          </ButtonMore>
          <div className='flex justify-center widescreen:justify-start widescreen:px-3 my-5'>
            <ThemeToggle />
          </div>
        </div>
      </div>
    </nav>
  )
}
