import { FC, ReactNode, useMemo } from 'react'
import IconInfo from '@haiper/icons-svg/icons/outline/info-circle.svg'
import BookOpenOutline from '@haiper/icons-svg/icons/outline/book-open.svg'
import Link from '@/components/link'
import IconPrivacy from '@haiper/icons-svg/icons/outline/lock.svg'
import IconAgreement from '@haiper/icons-svg/icons/outline/document-2.svg'
import IconLogout from '@haiper/icons-svg/icons/outline/logout.svg'
import QuestonmarkOutline from '@haiper/icons-svg/icons/outline/questionmark.svg'
import { LINKS, NO_OUTLINE_STYLE } from '@/constants'
import useAmplitude from '@/hooks/useAmplitude'
import ThemeToggle from '@/components/theme-toggle'
import { cls } from '@/utils'
import IconCareer from '@haiper/icons-svg/icons/outline/bag.svg'
import IconContact from '@haiper/icons-svg/icons/outline/bubble-annotation.svg'
import { useAtomValue } from 'jotai'
import { auth0SignInAtom } from '@/atoms'
import useFooterLinks from '@/hooks/useFooterLinks'
import usePartner from '@/hooks/usePartner'
import { NavLink } from '@/types'
import Popover from '@/components/popover'

interface ButtonMoreProps {
  children?: ReactNode
  withFooterLinks?: boolean
}

interface MoreLink {
  href: string
  icon: FC<any>
  label: string
  event: string
  target?: string
}

export function ButtonMore({ children, withFooterLinks }: ButtonMoreProps) {
  const { track } = useAmplitude()
  const iconClassName = 'size-6 text-icon'
  const isSignedIn = useAtomValue(auth0SignInAtom)
  const { data: footerLinks } = useFooterLinks()
  const { partner } = usePartner()

  const linkStyle = cls(
    'flex items-center gap-2 px-2 py-3 cursor-pointer m-[4px] rounded-lg bg-surface hover:bg-surface-hover active:bg-surface-hover',
    NO_OUTLINE_STYLE,
  )

  const baseLinks: MoreLink[] = useMemo(() => {
    return partner
      ? []
      : [
          {
            href: LINKS.about,
            icon: IconInfo,
            label: 'About',
            event: 'click:nav:about',
            target: '_blank',
          },
          {
            href: LINKS.careers,
            icon: IconCareer,
            label: 'Careers',
            event: 'click:nav:careers',
            target: '_blank',
          },
          {
            href: LINKS.contact,
            icon: IconContact,
            label: 'Contact',
            event: 'click:nav:contact',
            target: '_blank',
          },
          {
            href: LINKS.manual,
            icon: BookOpenOutline,
            label: 'Get Started',
            event: 'click:nav:manual',
            target: '_blank',
          },
          {
            href: LINKS.faq,
            icon: QuestonmarkOutline,
            label: 'FAQ',
            event: 'click:nav:faq',
            target: '_blank',
          },
          {
            href: LINKS.privacy,
            icon: IconPrivacy,
            label: 'Privacy Policy',
            event: 'click:nav:privacy',
            target: '_blank',
          },
          {
            href: LINKS.termsOfUse,
            icon: IconAgreement,
            label: 'Terms and Conditions',
            event: 'click:nav:terms',
            target: '_blank',
          },
        ]
  }, [partner])

  const moreLinks: NavLink[] = useMemo(() => {
    return [
      ...baseLinks,
      ...(isSignedIn
        ? [
            {
              href: '/auth/signout',
              icon: IconLogout,
              label: 'Logout',
              event: 'click:nav:signout',
            },
          ]
        : []),
    ]
  }, [baseLinks, isSignedIn])

  return (
    <Popover
      align='start'
      trigger={children as any}
      className='w-[226px] p-0 rounded-lg backdrop-blur-xl bg-surface text-text'
    >
      {withFooterLinks
        ? footerLinks.map(({ href, icon: Icon, label, key, target }) => (
          <Link
            key={key}
            className={linkStyle}
            href={href}
            target={target}
            rel='noopener'
            >
            <Icon className={iconClassName} />
            <span className='text-body-md'>{label}</span>
          </Link>
          ))
        : null}
      {moreLinks.map(({ href, icon: Icon, label, event, target }) => (
        <Link
          key={[href, target].join('')}
          className={linkStyle}
          href={href}
          target={target}
          rel='noopener'
          onClick={() => {
            if (event) {
              track(event)
            }
          }}
        >
          <Icon className={iconClassName} />
          <span className='text-body-md'>{label}</span>
        </Link>
      ))}
      <div className='px-2 py-3 md:hidden flex justify-start items-center'>
        <ThemeToggle className='flex md:hidden' />
      </div>
    </Popover>
  )
}
