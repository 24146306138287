// import IconInfo from '@haiper/icons-svg/icons/outline/info-circle.svg'
import IconNews from '@haiper/icons-svg/icons/outline/Newspaper.svg'
import IconBadgeVerifiedOutline from '@haiper/icons-svg/icons/outline/badge-verified.svg'
import IconBadgeVerifiedSolid from '@haiper/icons-svg/icons/solid/badge-verified.svg'
import IconCodeOutline from '@haiper/icons-svg/icons/outline/code.svg'
import IconCodeSolid from '@haiper/icons-svg/icons/solid/code.svg'

import usePartner from './usePartner'
import { useBreakpoint } from './useBreakPoint'

const useFooterLinks = () => {
  const { partner } = usePartner()
  const { isBelowMd } = useBreakpoint('md')

  if (partner) {
    return {
      data: [],
      loading: false,
    }
  }

  return {
    data: [
      ...(isBelowMd
        ? [
            {
              href: '/membership',
              key: 'membership',
              icon: IconBadgeVerifiedOutline,
              activeIcon: IconBadgeVerifiedSolid,
              label: 'Membership',
            },
          ]
        : []),
      // {
      //   href: 'https://haiper.ai/about',
      //   key: 'about',
      //   icon: IconInfo,
      //   label: 'About',
      //   target: '_blank',
      // },
      // {
      //   href: '/enterprise-api',
      //   key: 'enterprise-api',
      //   icon: IconCodeOutline,
      //   activeIcon: IconCodeSolid,
      //   label: 'API',
      //   target: '_blank',
      // },
      {
        href: '/haiper-api',
        key: 'api',
        icon: IconCodeOutline,
        activeIcon: IconCodeSolid,
        label: 'API',
      },
      {
        href: '/blog',
        key: 'blog',
        icon: IconNews,
        label: 'Blog',
        target: '_blank',
      },
    ],
    loading: false,
  }
}

export default useFooterLinks
