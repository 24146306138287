import {
  Popover as BasePopover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import { cls, whisper } from '@/utils'
import { PopoverContentProps } from '@radix-ui/react-popover'
import {
  ComponentPropsWithoutRef,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

export type PopoverProps = PropsWithChildren<
  {
    trigger: ReactElement
    className?: string
    popOnHover?: boolean
    align?: PopoverContentProps['align']
    side?: PopoverContentProps['side']
  } & ComponentPropsWithoutRef<typeof BasePopover>
>

export default function Popover({
  trigger,
  children,
  className,
  popOnHover,
  align = 'center',
  side,
  open,
  onOpenChange,
  ...props
}: PopoverProps) {
  const [triggerHovering, setTriggerHovering] = useState(false)
  const [contentHovering, setContentHovering] = useState(false)

  const triggerHoveringTimer = useRef<any>(null)
  const contentHoveringTimer = useRef<any>(null)

  const handleTrigerMouseEnter = useCallback(() => {
    if (triggerHoveringTimer.current) {
      clearTimeout(triggerHoveringTimer.current)
    }
    triggerHoveringTimer.current = setTimeout(() => {
      setTriggerHovering(true)
    }, 200)
  }, [])

  const handleTriggerMouseLeave = useCallback(() => {
    if (triggerHoveringTimer.current) {
      clearTimeout(triggerHoveringTimer.current)
    }
    triggerHoveringTimer.current = setTimeout(() => {
      setTriggerHovering(false)
    }, 200)
  }, [])

  const handleContentMouseEnter = useCallback(() => {
    if (contentHoveringTimer.current) {
      clearTimeout(contentHoveringTimer.current)
    }
    setContentHovering(true)
  }, [])

  const handleContentMouseLeave = useCallback(() => {
    if (contentHoveringTimer.current) {
      clearTimeout(contentHoveringTimer.current)
    }
    contentHoveringTimer.current = setTimeout(() => {
      setContentHovering(false)
    }, 200)
  }, [])

  const realOpen =
    open ?? (popOnHover ? triggerHovering || contentHovering : open)

  useEffect(() => {
    if (realOpen !== undefined) {
      onOpenChange?.(realOpen)
    }
  }, [realOpen, onOpenChange])

  const { isBelowMd } = useBreakpoint('md')

  return (
    <BasePopover open={realOpen} onOpenChange={onOpenChange} {...props}>
      <PopoverTrigger
        asChild
        onMouseEnter={handleTrigerMouseEnter}
        onMouseLeave={handleTriggerMouseLeave}
      >
        {trigger}
      </PopoverTrigger>
      <PopoverContent
        collisionPadding={16}
        sideOffset={8}
        className={cls('p-4 w-[300px] backdrop-blur-xl rounded-lg', className)}
        align={align}
        side={side}
        data-component='creation-input'
        data-outside='false'
        updatePositionStrategy='optimized'
        onOpenAutoFocus={(e) => e?.preventDefault()}
        onMouseEnter={isBelowMd ? undefined : handleContentMouseEnter}
        onMouseLeave={isBelowMd ? undefined : handleContentMouseLeave}
        onScroll={(e) => {
          if (isBelowMd && e.target === e.currentTarget) {
            whisper('popover-scroll', e)
            const div = e.target as HTMLDivElement
            if (div.scrollTop + div.clientHeight >= div.scrollHeight) {
              whisper('popover-scroll-bottom', e)
              // scroll up a bit to prevent iOS hanging
              div.scrollTop = div.scrollHeight - div.clientHeight - 8
            }
          }
        }}
      >
        {children}
        <div className='h-2 md:hidden' />
      </PopoverContent>
    </BasePopover>
  )
}
