'use client'

import CompassSolid from '@haiper/icons-svg/icons/solid/compass-circle.svg'
import CompassOutline from '@haiper/icons-svg/icons/outline/compass-circle.svg'

import PeopleOutline from '@haiper/icons-svg/icons/outline/people.svg'
import PeopleSolid from '@haiper/icons-svg/icons/solid/people.svg'

import LoginOutline from '@haiper/icons-svg/icons/outline/login.svg'
import LoginSolid from '@haiper/icons-svg/icons/solid/login.svg'

import LightOutine from '@haiper/icons-svg/icons/outline/light-bulb.svg'
import LightSolid from '@haiper/icons-svg/icons/solid/light-bulb.svg'

import PlusOutline from '@haiper/icons-svg/icons/outline/plus-large.svg'
import PlusSolid from '@haiper/icons-svg/icons/solid/plus-large.svg'

import useHasSignIn from './useHasSignIn'
import usePartner from './usePartner'
import useAuth0Auth from './useAuth0Auth'
import { NavLink } from '@/types'
import { useMemo } from 'react'
import { useCachedMyProfile } from './useMyProfile'

import IconBadgeVerifiedOutline from '@haiper/icons-svg/icons/outline/badge-verified.svg'
import IconBadgeVerifiedSolid from '@haiper/icons-svg/icons/solid/badge-verified.svg'
import { useBreakpoint } from './useBreakPoint'

const useNavLinks = (): { data: NavLink[]; loading: boolean } => {
  const isLogin = useHasSignIn()
  const loading = false
  const { partner } = usePartner()
  const { login } = useAuth0Auth()
  const { data: profile } = useCachedMyProfile()
  const { isBelowMd } = useBreakpoint('md')

  const creationLink: NavLink = useMemo(() => {
    return {
      href: '/creations',
      key: 'creations',
      icon: PlusOutline,
      activeIcon: PlusSolid,
      className:
        'bg-surface-primary text-text-on-color hover:text-text-on-color hover:bg-surface-primary-hover active:bg-surface-primary-active',
      iconClassName: 'text-icon-on-color',
      labelClassName: 'text-text-on-color hover:text-text-on-color',
      label: 'Create',
    }
  }, [])

  const createLinks: NavLink[] = useMemo(() => {
    if (partner) {
      return []
    }
    return loading
      ? []
      : isLogin
        ? [creationLink]
        : [
            {
              href: '#',
              key: 'signin',
              icon: LoginOutline,
              activeIcon: LoginSolid,
              label: 'Login to create',
              className:
                'bg-surface-primary text-text-on-color hover:text-text-on-color hover:bg-surface-primary-hover active:bg-surface-primary-active',
              iconClassName: 'text-icon-on-color',
              labelClassName: 'text-text-on-color hover:text-text-on-color',
              onClick: login,
            },
          ]
  }, [isLogin, loading, login, partner, creationLink])

  if (partner) {
    return {
      data: [creationLink],
      loading,
    }
  }

  return {
    data: [
      {
        href: '/',
        key: 'explore',
        icon: CompassOutline,
        activeIcon: CompassSolid,
        label: 'Explore',
      },
      {
        href: '/spotlight',
        key: 'spotlight',
        icon: LightOutine,
        activeIcon: LightSolid,
        label: 'Spotlight',
      },
      ...(isBelowMd ? createLinks : []),
      {
        href: profile ? `/profile/${profile?.user_id}` : '#',
        key: 'profile',
        icon: PeopleOutline,
        activeIcon: PeopleSolid,
        label: 'Profile',
      },
      ...(isBelowMd
        ? []
        : [
            {
              href: '/membership',
              key: 'membership',
              icon: IconBadgeVerifiedOutline,
              activeIcon: IconBadgeVerifiedSolid,
              label: (
                <div className='flex gap-1 items-center'>
                  <span>Membership</span>
                  <div className='h-6 flex items-start' aria-label='new badge'>
                    <div className='h-4 rounded-[5px] border border-solid border-border px-1 py-0.5 text-text-interactive text-xs font-bold flex items-center justify-center'>
                      NEW
                    </div>
                  </div>
                </div>
              ),
            },
          ]),
      ...(isBelowMd ? [] : createLinks),
    ],
    loading,
  }
}

export default useNavLinks
